<template>
  <div
    class="audio-player"
    :style="{width}"
  >
    <template v-if="hasItems && showUi">
      <div class="main-controls">
        <a
          v-show="buttons.prev"
          class="control-btn"
          :class="prevClasses"
          href="#"
          @click.prevent="prevItemClicked"
        >
          <img
            :src="img.prevSong"
            alt="anterior"
          >
        </a>
        <a
          v-show="buttons.play"
          class="control-btn"
          :class="playPauseClasses"
          href="#"
          @click.prevent="playClicked"
        >
          <img
            v-if="!isPlaying"
            :src="img.play"
            alt="pausar"
          >
          <img
            v-if="isPlaying"
            :src="img.pause"
            alt="reproducir"
          >
        </a>
        <a
          v-show="buttons.stop"
          class="control-btn"
          :class="stopClasses"
          href="#"
          @click.prevent="stopClicked"
        >
          <img
            :src="img.stop"
            alt="parar"
          >
        </a>
        <a
          v-show="buttons.next"
          class="control-btn"
          :class="nextClasses"
          href="#"
          @click.prevent="nextItemClicked"
        >
          <img
            :src="img.nextSong"
            alt="siguiente"
          >
        </a>
        <a
          v-show="buttons.mode"
          class="control-btn"
          href="#"
          @click.prevent="changePlayListMode"
        >
          <img
            :src="playListModeImg"
            :alt="playListMode"
          >
        </a>
        <a
          v-show="buttons.tempo"
          v-if="hasTempo"
          class="control-btn tempo-selector-btn"
          :class="tempoClasses"
          href="#"
          @click.prevent="showTempoSelector"
        >
          <img
            :src="img.tempoDefault"
            alt="tempo"
          >
        </a>
        <slot name="extra-slot" />
      </div>
    </template>
    <template v-if="hasItems && showUi">
      <div class="secondary-controls">
        <div
          ref="trackBar"
          v-touch:tap="changeCurrentTime"
          class="track-bar"
          @mousemove="moveDragHandler"
          @mouseleave="endDragHandler"
        >
          <span class="track-bar-bg" />
          <a
            ref="trackBall"
            class="track-ball"
            href="#"
            style="left: 0;"
          />
          <template v-if="trackMode === 'studio'">
            <a
              ref="trackBallStart"
              class="track-ball-start"
              href="#"
              data-position="left"
              @mousedown="startDragHandler"
              @mouseup="endDragHandler"
            />
            <a
              ref="trackBallEnd"
              class="track-ball-end"
              href="#"
              data-position="right"
              @mousedown="startDragHandler"
              @mouseup="endDragHandler"
            />
          </template>
        </div>
        <div class="time-labels">
          {{ currentTimeLabel }} / {{ totalTimeLabel }}
        </div>
      </div>
    </template>
    <b-modal
      v-model="showTempoModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Selección tempo"
      aria-modal
    >
      <template #default="props">
        <tempo-selector
          :variant="tempoData.variant"
          :tempo="tempoData.tempo"
          @close="props.close"
          @change="tempoChanged"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import TempoSelector from "@/components/modals/TempoSelector";
import NumberUtils from '@/utils/number'
import ImageUtils from '@/utils/image'
import PauseImg from '@/assets/img/controls/pause.png'
import PlayImg from '@/assets/img/controls/play.png'
import NextSongImg from '@/assets/img/controls/next-item-alt.png'
import PrevSongImg from '@/assets/img/controls/prev-item-alt.png'
import StopImg from '@/assets/img/controls/stop.png'
import PlayModeSingleImg from '@/assets/img/controls/mode-single.png'
import PlayModeContinuousImg from '@/assets/img/controls/mode-continuous.png'
import TempoDefault from '@/assets/img/controls/tempo_0.png'
import Tempo1 from '@/assets/img/controls/tempo_1.png'
import Tempo2 from '@/assets/img/controls/tempo_2.png'
import Tempo3 from '@/assets/img/controls/tempo_3.png'
import Tempo4 from '@/assets/img/controls/tempo_4.png'
import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();
const playListModes = ['single', 'continuous']
const trackModes = ['default', 'studio']
const tempoModes = ['general','media']

export default {
  name: "AudioPlayerPlayList",
  components: {
    TempoSelector
  },
  props: {
    hasTempo: {
      type: Boolean,
      default: false
    },
    tempoVariant: {
      type: String,
      default: 'media',
      validator: (value) => {
        return tempoModes.includes(value)
      }
    },
    playbackRate: {
      type: Number,
      default: 1
    },
    playlist: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedIndex: {
      type: Number,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    showUi: {
      type: Boolean,
      default: true
    },
    playListMode: {
      type: String,
      default: 'continuous',
      validator: (value) => {
        return playListModes.includes(value)
      }
    },
    trackMode: {
      type: String,
      default: 'default',
      validator: (value) => {
        return trackModes.includes(value)
      }
    },
    buttons: {
      type: Object,
      default: () => {
        return {
          prev: true,
          play: true,
          stop: true,
          next: true,
          mode: true,
          tempo: true
        }
      }
    },
    preload: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data: function () {
    const trackBallWidth = 20
    return {
      playListModes: playListModes,
      trackModes: trackModes,
      img: {
        play: PlayImg,
        pause: PauseImg,
        stop: StopImg,
        nextSong: NextSongImg,
        prevSong: PrevSongImg,
        tempoDefault: TempoDefault,
        tempo1: Tempo1,
        tempo2: Tempo2,
        tempo3: Tempo3,
        tempo4: Tempo4,
        playListMode: {
          single: PlayModeSingleImg,
          continuous: PlayModeContinuousImg
        }
      },
      REFRESH_TIME: 250,
      trackBallWidth: trackBallWidth,
      trackBallHalfWidth: (trackBallWidth / 2),
      audioEngine: null,
      isPlaying: false,
      isPaused: false,
      loaded: false,
      trackbarInt: null,
      totalAudioTime: null,
      currentAudioTime: null,
      showTempoModal: false,
      tempoData: {
        tempo: this.playbackRate,
        variant: 'media'
      },
      audio: '',
      avoidAutoplay: false,
      trackBar: {
        marginLeft: 10,
        sizeHandler: false,
        max: 0
      },
      studioMode: {
        dragging: false,
        separation: 20,
        target: null,
        targetPosition: null,
        start: 0,
        end: 0,
        minTime: 0,
        maxTime: 0
      },
    }
  },
  computed: {
    playListModeImg() {
      return this.img.playListMode[this.playListMode]
    },
    hasItems() {
      return this.playlist.length > 0
    },
    currentTimeLabel() {
      if (!this.audioEngine) {
        return this.secondsToTimeLabel()
      }

      return this.secondsToTimeLabel(this.currentAudioTime)
    },
    totalTimeLabel() {
      return this.secondsToTimeLabel(this.totalAudioTime)
    },
    showStopBtn() {
      return this.isPlaying === true || this.isPaused === true
    },
    playPauseClasses() {
      if (!this.hasItems || this.selectedIndex === null) {
        return 'disabled'
      }

      return ''
    },
    stopClasses() {
      if (!this.hasItems || this.selectedIndex === null || !this.isPlaying) {
        return 'disabled'
      }

      return ''
    },
    prevClasses() {
      if (!this.hasItems || this.selectedIndex === null || this.selectedIndex === 0) {
        return 'disabled'
      }

      return ''
    },
    nextClasses() {
      if (!this.hasItems || this.selectedIndex === null || (this.selectedIndex + 1) > this.playlist.length) {
        return 'disabled'
      }

      return ''
    },
    tempoClasses() {
      //if (!this.hasItems || this.selectedIndex === null) {
      if (!this.hasItems) {
        return 'disabled'
      }

      return ''
    }
  },
  watch: {
    playlist() {
      this.updateAudio(this.selectedIndex)
    },
    selectedIndex(newVal) {
      if (newVal < 0 || newVal >= this.playlist.length) {
        return
      }

      this.updateAudio(newVal)
    },
    async trackMode(newVal) {
      if (newVal === 'studio') {
        await this.initStudioTrackBalls()
      }
    },
    tempoVariant(newValue){
      this.tempoData.variant = newValue
    }
  },
  mounted() {
    this.tempoData.variant = this.tempoVariant
    if (this.preload) {
      this.updateAudio(this.selectedIndex)
    } else {
      this.initPlayer()
    }
  },
  beforeDestroy() {
    this.removeTrackBarSizeHandler()
    this.stopClicked()
  },
  methods: {
    createTrackBarSizeHandler() {
      this.trackBar.max = this.$refs.trackBar.clientWidth - this.trackBallWidth
      this.$refs.trackBar.addEventListener("resize", this.updateTrackBarSize, true);
    },
    removeTrackBarSizeHandler() {
      this.$refs.trackBar.removeEventListener("resize", this.updateTrackBarSize, true);
    },
    updateTrackBarSize() {
      this.trackBar.max = this.$refs.trackBar.clientWidth - this.trackBallWidth
    },
    updateAudio(index) {
      const selectedSong = this.playlist[index]

      if (!selectedSong) {
        return
      }

      if (selectedSong?.blob) {
        this.audio = URL.createObjectURL(selectedSong.blob);
      } else {
        this.audio = this.getSongPath(selectedSong.audio)
      }

      this.initPlayer()
    },
    playClicked() {
      if (!this.loaded) {
        this.updateAudio(this.selectedIndex)
      }

      if (this.loaded) {
        if (!this.isPlaying) {
          noSleep.enable();

          this.audioEngine.play()
        }

        if (this.isPlaying) {
          noSleep.disable();
          this.audioEngine.pause()
        }
      }
    },
    stopClicked() {
      if (this.loaded) {
        this.avoidAutoplay = true
        this.stopAudio()
      }
    },
    initPlayer() {
      if (!this.audio) {
        return
      }

      if (this.trackbarInt) {
        clearInterval(this.trackbarInt);
      }
      if (this.audioEngine && this.isPlaying) {
        this.playClicked()
      }
      this.audioEngine = new Audio()
      this.audioEngine.playbackRate = this.tempoData.tempo
      this.audioEngine.src = this.audio
      this.audioEngine.oncanplaythrough = () => {
        this.audioEngine.playbackRate = this.tempoData.tempo
        this.currentAudioTime = this.audioEngine.currentTime
        this.totalAudioTime = this.audioEngine.duration

        if (!this.avoidAutoplay && this.autoPlay && !this.isPlaying) {
          this.playClicked()
        } else {
          this.avoidAutoplay = false
        }

        this.$emit('loaded', {
          totalTime: this.audioEngine.duration,
          currentTime: this.currentAudioTime,
          playbackrate: this.tempoData.tempo
        })
      }
      this.audioEngine.load = () => {
        this.loaded = true
        this.isPlaying = false
        this.isPaused = false
      }

      this.audioEngine.onplaying = this.playAudio
      this.audioEngine.onpause = this.pauseAudio
      this.audioEngine.onended = this.endedAudio

      this.audioEngine.load()

      setTimeout(() => {
        this.createTrackBarSizeHandler()
      }, 200)
    },
    playAudio() {
      this.isPlaying = true
      this.isPaused = false

      clearInterval(this.trackbarInt)

      this.trackbarInt = setInterval(() => {
        this.refreshProgress()
      }, this.REFRESH_TIME)

      this.$emit('change-status', 'play')
    },
    pauseAudio() {
      if (this.isPlaying) {
        clearInterval(this.trackbarInt)
        this.isPaused = true
        this.isPlaying = false

        this.$emit('change-status', 'pause')
      }
    },
    stopAudio() {
      if (this.isPlaying || this.isPaused) {
        noSleep.disable();
        this.audioEngine.pause()
        clearInterval(this.trackbarInt)
        if(this.trackMode === 'studio'){
          this.currentAudioTime = this.studioMode.minTime
          this.audioEngine.currentTime = this.studioMode.minTime
        }else{
          this.currentAudioTime = 0
          this.audioEngine.currentTime = 0
        }

        this.isPlaying = false
        this.isPaused = false
        this.refreshProgress()
        this.$emit('change-status', 'stop')
      }
    },
    endedAudio() {
      this.avoidAutoplay = true
      this.stopAudio()

      if (this.playListMode === 'continuous') {
        this.nextItemClicked()
      }
    },
    refreshProgress() {
      if (this.trackMode === 'studio') {
        if (this.currentAudioTime < this.studioMode.minTime || this.currentAudioTime > this.studioMode.maxTime) {
          this.stopClicked()
          this.playClicked()
        }
      }

      this.currentAudioTime = this.audioEngine.currentTime

      if (this.showUi) {
        const percent = NumberUtils.getPercent(this.currentAudioTime, this.totalAudioTime)
        const trackBarPos = Math.round(this.trackBar.max * percent / 100)

        this.$refs.trackBall.style.left = trackBarPos + 'px'
      }

      const timeEvent = {
        interval: this.REFRESH_TIME / 1000,
        current_time: this.currentAudioTime,
        total_time: this.totalAudioTime,
        playback_rate: this.tempoData.tempo
      }

      this.$emit('change-cue', timeEvent)
    },
    secondsToTimeLabel(timeInSeconds = null) {
      if (null === timeInSeconds) {
        return "00:00"
      }

      let minutes = Math.floor(Math.round(timeInSeconds) / 60)
      let seconds = Math.round(timeInSeconds - minutes * 60)
      if (seconds < 0) {
        seconds = 0
      }

      return this.strPadLeft(minutes, '0', 2) + ':' + this.strPadLeft(seconds, '0', 2)
    },
    strPadLeft(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length)
    },
    showTempoSelector() {
      /*if (this.selectedIndex === null) {
        return
      }*/
      this.showTempoModal = true
    },
    tempoChanged(tempo) {
      if (tempo) {
        if(this.audioEngine){
          this.audioEngine.playbackRate = tempo.value
        }
        this.tempoData.tempo = tempo.value
      }
    },
    changeCurrentTime(event) {
      if(event.type === 'touchend'){
        event = event.changedTouches[0]
      }
      let position = event.clientX

      this.goToBarCurrentTime(position - this.trackBar.marginLeft - this.trackBallHalfWidth)
    },
    goToBarCurrentTime(position) {
      if (this.selectedIndex === null) {
        return
      }
      if (this.trackMode === 'studio' && (position < this.studioMode.start || position > this.studioMode.end)) {
        return
      }

      let targetX = position
      if (targetX < 0) {
        targetX = 0
      }
      if (targetX > this.trackBar.max) {
        targetX = this.trackBar.max
      }

      console.info(position, this.trackBar.max)
      let newCurrentPercent = (position * 100) / this.trackBar.max;
      newCurrentPercent = newCurrentPercent > 100 ? 100 : newCurrentPercent

      this.audioEngine.currentTime = (this.totalAudioTime * newCurrentPercent) / 100;

      this.refreshProgress()
    },
    prevItemClicked() {
      if (this.selectedIndex === null) {
        return
      }
      let newIndex = this.selectedIndex - 1
      if (this.selectedIndex === 0) {
        newIndex = this.playlist.length - 1
      }

      this.$emit('change-track', {item: this.playlist[newIndex], index: newIndex, type: 'prev'})
    },
    nextItemClicked() {
      if (this.selectedIndex === null) {
        return
      }
      let newIndex = this.selectedIndex + 1
      if (this.selectedIndex + 1 >= this.playlist.length) {
        newIndex = 0
      }

      this.$emit('change-track', {item: this.playlist[newIndex], index: newIndex, type: 'next'})
    },
    changePlayListMode() {
      let currentSelIndex = this.playListModes.indexOf(this.playListMode)

      currentSelIndex++

      if (currentSelIndex >= this.playListModes.length) {
        currentSelIndex = 0
      }

      this.$emit('change-mode', this.playListModes[currentSelIndex])
    },
    getSongPath(path) {
      return ImageUtils.getCDNFullPath(path)
    },
    setCurrentTime(cue) {
      if (!this.audioEngine) {
        return
      }
      this.audioEngine.currentTime = cue / 1000
      this.refreshProgress()
    },
    startDragHandler(e) {
      e.preventDefault()
      this.studioMode.target = e.target
      this.studioMode.targetPosition = e.target.dataset.position
      this.studioMode.dragging = true
    },
    endDragHandler(e) {
      e.preventDefault()
      if (!this.studioMode.target || !this.studioMode.dragging) {
        return
      }
      this.studioMode.dragging = false
      this.studioMode.target = null
      this.studioMode.start = parseInt(this.$refs.trackBallStart.style.left.split("px").join(""))
      if(Number.isNaN(this.studioMode.start)){
        this.studioMode.start = 0
      }
      this.studioMode.end = parseInt(this.$refs.trackBallEnd.style.left.split("px").join(""))
      this.adjustTimeBounds()
      this.adjustTimeMarker()
    },
    moveDragHandler(e) {
      if (!this.studioMode.target || !this.studioMode.dragging) {
        return
      }
      const minPos = this.studioMode.targetPosition === 'left' ? 0 : (parseInt(this.studioMode.start) + this.trackBallWidth + this.studioMode.separation)
      const maxPos = this.studioMode.targetPosition === 'right' ? this.trackBar.max : (this.studioMode.end - this.trackBallWidth - this.studioMode.separation)
      let targetPos = Math.ceil(e.clientX - this.trackBar.marginLeft - this.trackBallHalfWidth)

      targetPos = (targetPos < minPos) ? minPos : targetPos
      targetPos = (targetPos > maxPos) ? maxPos : targetPos

      this.studioMode.target.style.left = targetPos + 'px'
    },
    async initStudioTrackBalls() {
      await this.$nextTick()
      this.studioMode.start = 0
      this.studioMode.end = this.trackBar.max
      this.studioMode.minTime = 0
      this.studioMode.maxTime = this.totalAudioTime

      this.$refs.trackBallStart.style.left = this.trackBar.min + 'px'
      this.$refs.trackBallEnd.style.left = this.trackBar.max + 'px'
    },
    adjustTimeBounds() {
      const percentMin = this.studioMode.start / this.trackBar.max
      const percentMax = this.studioMode.end / this.trackBar.max
      this.studioMode.minTime = this.totalAudioTime * percentMin
      this.studioMode.maxTime = this.totalAudioTime * percentMax
    },
    adjustTimeMarker() {
      if (this.trackMode !== 'studio') {
        return
      }

      let trackBallPos = parseInt(this.$refs.trackBall.style.left.split('px').join(''))
      if(!trackBallPos){

        trackBallPos = 0
      }

      if (trackBallPos >= this.studioMode.start && trackBallPos <= this.studioMode.end) {
        return
      }
      //siempre se ajusta al marcador de la izquierda
      this.$refs.trackBall.style.left = this.studioMode.start + 'px'

      this.goToBarCurrentTime(this.studioMode.start)
    }
  }
}
</script>
