<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <section class="modal-card-body">
      <div class="tempo-selector">
        <img
          class="tempo-bg"
          :src="bgImage"
          alt="tempo fondo"
        >
        <a
          v-for="(item, index) in playRateList"
          :key="index"
          class="tempo-area"
          href="#"
          :data-tempo="index"
          @click.prevent="selectTempo(index)"
        />
        <img
          class="tempo-pointer"
          src="~@/assets/img/tempo/tempo-pointer.png"
          :data-tempo="selectedIndex"
          alt="tempo aguja"
        >
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button
        label="Volver"
        @click="$emit('close')"
      />
    </footer>
  </div>
</template>

<script>
import tempoMedia from '@/assets/img/tempo/tempo-bg-media.png'
import tempoGeneral from '@/assets/img/tempo/tempo-bg-general.png'
const variants = ['general','media']

export default {
  name: "TempoSelector",
  props: {
    tempo: {
      type: Number,
      default: 0
    },
    variant: {
      type: String,
      default: 'media',
      validator: (value) => {
        return variants.includes(value)
      }
    }
  },
  data: function() {
    return {
      selectedIndex: 2,
      bgs: {
        media: tempoMedia,
        general: tempoGeneral
      },
      playRates: {
        media: [
          {
            text: '+2',
            value: 1.33333,
            color: '#CCCCCC'
          },
          {
            text: '+1',
            value: 1.16666,
            color: '#B5C0CA'
          },
          {
            text: '+0',
            value: 1.0,
            color: '#709DC3'
          },
          {
            text: '-1',
            value: 0.85,
            color: '#87A9C5'
          },
          {
            text: '-2',
            value: 0.75,
            color: '#9EB5C8'
          },
        ],
        general: [
          {
            img: 'x1',
            value: 1.0,
            color: '#709DC3'
          },
          {
            text: 'x0.85',
            value: 0.85,
            color: '#87A9C5'
          },
          {
            text: 'x0.75',
            value: 0.75,
            color: '#9EB5C8'
          },
          {
            text: 'x0.65',
            value: 0.65,
            color: '#B5C0CA'
          },
          {
            text: 'x0.55',
            value: 0.55,
            color: '#CCCCCC'
          }
        ]
      }
    }
  },
  computed: {
    bgImage(){
      return this.bgs[this.variant]
    },
    playRateList() {
      return this.playRates[this.variant]
    }
  },
  mounted() {
    this.selectedIndex = this.getSelectedIndexByTempo(this.tempo)
  },
  methods: {
    selectTempo(index){
      this.selectedIndex = index
      const selectedTempo = this.playRateList[index]

      setTimeout(() => {
        this.$emit('change',selectedTempo)
        this.$emit('close')
      },600)

    },
    getSelectedIndexByTempo(tempo){
      return this.playRateList.findIndex((item) => item.value === parseFloat(tempo))
    }
  }
}
</script>
