class NumberUtils {
  getPercent(n, total, decimals = 2) {
    const percent = (100 * n) / total
    const multiplier = Math.pow(10, 2)
    return Math.round(percent * multiplier) / multiplier
  }

  isBetweenRange(reference, lowerLimit, upperLimit, includeBorders = true) {
    if (includeBorders) {
      return reference >= lowerLimit && reference <= upperLimit
    }

    if (!includeBorders) {
      return reference > lowerLimit && reference < upperLimit
    }
  }

  getRandomNumber(min, max, exclusions) {
    let hash = {};
    for(let i = 0; i < exclusions.length; ++i ) {
      hash[exclusions[i]] = i + max - exclusions.length;
    }

    let randomNumber = Math.floor((Math.random() * (max - min - exclusions.length)) + min);
    if (hash.hasOwnProperty(randomNumber)) {
      randomNumber = hash[randomNumber];
    }
    return randomNumber;
  }
}

export default new NumberUtils()
